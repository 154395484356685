'use client';

import { Fragment, ReactElement } from 'react';
import { toKebabCase } from '@/utils/to-kebab-case';
import { BasePageBlock } from '@/utils/types';
import { CommonComponent } from '@/blocks/CommonComponent';
import { Features } from '@/blocks/Features';
import { SingleBenefit } from '@/blocks/SingleBenefit';
import { IconsWithText } from '@/blocks/IconsWithText';
import { RichText } from '@/blocks/RichText';
import { StickyButton } from '@/blocks/StickyButton';
import { RichTextEditor } from '@/blocks/RichTextEditor';
import { Accordions } from '@/components/Accordions';
import { SimpleCta } from '@/blocks/SimpleCta';
import { ExtendedReviews } from '@/blocks/ExtendedReviews';
import { OfferTable } from '@/blocks/OfferTable';
import { VideoCta } from '@/blocks/VideoCta';
import { useStickyButtonContext } from '@/providers/StickyButton';
import { BenefitsBlock } from '@/blocks/BenefitsBlock';
import { Cta } from '@/blocks/Cta';
import { Faqs } from '@/blocks/Faqs';

type BlockComponents = {
  commonComponent: typeof CommonComponent;
  features: typeof Features;
  singleBenefit: typeof SingleBenefit;
  iconsWithText: typeof IconsWithText;
  RichText: typeof RichText;
  stickyButton: typeof StickyButton;
  RichTextEditor: typeof RichTextEditor;
  accordions: typeof Accordions;
  simpleCTA: typeof SimpleCta;
  'extended-reviews': typeof ExtendedReviews;
  offerTable: typeof OfferTable;
  VideoCta: typeof VideoCta;
  benefitsBlock: typeof BenefitsBlock;
  Cta: typeof Cta;
  Faqs: typeof Faqs;
  reusableContentBlock: null;
};

const blockComponents: BlockComponents = {
  commonComponent: CommonComponent,
  features: Features,
  singleBenefit: SingleBenefit,
  iconsWithText: IconsWithText,
  RichText,
  stickyButton: StickyButton,
  RichTextEditor,
  accordions: Accordions,
  simpleCTA: SimpleCta,
  'extended-reviews': ExtendedReviews,
  offerTable: OfferTable,
  VideoCta,
  benefitsBlock: BenefitsBlock,
  Cta,
  Faqs,
  reusableContentBlock: null
};
const blocksWithTitle = ['accordions', 'RichTextEditor'];
const blocksWithCta = ['VideoCta'];

type RenderBlocksProps = {
  blocks: BasePageBlock[];
  title?: string;
  searchParams?: { [key: string]: string | string[] | undefined };
};

export const RenderBlocks = ({ blocks, title }: RenderBlocksProps) => {
  const hasBlocks = blocks && Array.isArray(blocks) && blocks.length > 0;
  let titleRendered = false;
  const { setShowStickyButton } = useStickyButtonContext();

  if (!blocks.some(block => blocksWithCta.includes(block.blockType))) {
    setShowStickyButton(true);
  }

  const renderBlocksHandler = (block: BasePageBlock, index: number): ReactElement | null => {
    const { blockName, blockType } = block;

    if (blockType === 'reusableContentBlock' && block.reusableContent) {
      if (typeof block.reusableContent === 'string') {
        return <>{block.reusableContent}</>;
      }
      return <RenderBlocks blocks={block.reusableContent.layout} />;
    }

    if (blockType && blockType in blockComponents) {
      const Block = blockComponents[blockType];
      const additionalProps: {
        title?: string;
      } = {};

      if (blocksWithTitle.includes(blockType) && !titleRendered) {
        additionalProps.title = title;
        titleRendered = true;
      }

      const finalBlocks = { ...block, ...additionalProps };

      if (Block) {
        return (
          // @ts-expect-error impossible to know type of block as this data comes from API
          <Block id={toKebabCase(blockName || index.toString())} key={index} {...finalBlocks} />
        );
      }
    }
    return null;
  };

  if (hasBlocks) {
    return <Fragment>{blocks.map(renderBlocksHandler)}</Fragment>;
  }

  return null;
};
