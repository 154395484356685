'use client';

import { useEffect, useRef, RefObject, CSSProperties } from 'react';
import classes from './index.module.scss';
import { Button } from '@/components/Button';
import { useStickyButtonContext } from '@/providers/StickyButton';
import { ExtractBlockField } from '@/utils/types';
import { clsx } from 'clsx';

type StickyButtonProps = {
  stickyButton: ExtractBlockField<'stickyButton', 'stickyButton'>;
  blog?: boolean;
};

export const StickyButton = (props: StickyButtonProps) => {
  const {
    stickyButton: { heading, buttonPosition, link, background, text, buttonBackground, buttonTextColor },
    blog
  } = props;

  const { showStickyButton } = useStickyButtonContext();
  const section: RefObject<HTMLElement> = useRef(null);

  useEffect(() => {
    if (!section.current) {
      return;
    }

    const { height } = section.current?.getBoundingClientRect() || { height: 0 };
    document.documentElement.style.setProperty(
      '--sticky-button',
      (showStickyButton || showStickyButton === undefined ? height : 0) + 'px'
    );

    return () => {
      document.documentElement.style.setProperty('--sticky-button', '0px');
    };
  }, [showStickyButton]);

  if (!link) {
    return;
  }

  const style: CSSProperties = {
    background: String(background),
    color: String(text),
    ['--button-background' as string]: buttonBackground,
    ['--button-text' as string]: buttonTextColor
  };

  const sectionClasses = `${classes.section} ${(buttonPosition && classes[buttonPosition]) || ''} ${showStickyButton || showStickyButton === undefined ? classes.visible : ''} ${blog ? classes.blog : ''}`;

  return (
    <section className={sectionClasses} ref={section} style={style}>
      <div className={clsx({ blog: 'container medium' })}>
        <div className={classes.content}>
          {heading && <div className={classes.heading}>{heading}</div>}

          <Button link={link} size={'medium'} additionalClasses={classes.link} />
        </div>
      </div>
    </section>
  );
};
