import { Media } from '@/components/Media';
import type { ExtractBlockField } from '@/utils/types';
import classes from './index.module.scss';
import { CMSLink } from '@/components/CMSLink';
import { clsx } from 'clsx';

type SingleBenefitProps = {
  singleBenefit: ExtractBlockField<'singleBenefit', 'singleBenefit'>;
};

export const SingleBenefit = ({
  singleBenefit: { imagePosition, image, titleIcon, title, description, link, listLabel, listItem }
}: SingleBenefitProps) => {
  return (
    <section>
      <div className={clsx(classes.container, { [classes.reversed]: imagePosition === 'left' })}>
        <div className={classes.contentBlock}>
          {title && (
            <div className={classes.contentTitle}>
              {titleIcon && (
                <span>
                  <Media source={titleIcon} width={39} height={47} />
                </span>
              )}

              {title}
            </div>
          )}

          {description && (
            <div className={classes.contentDescription} dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {listItem?.length && (
            <div className={classes.listWrapper}>
              {listLabel && <h4 className={classes.listTitle}>{listLabel}</h4>}

              <ul>
                {listItem.map(({ text }, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ul>
            </div>
          )}

          {link && (
            <div className={classes.btnWrapper}>
              <CMSLink {...link} className={classes.btn} />
            </div>
          )}
        </div>
        {image && (
          <div className={classes.imgWrapper}>
            <Media source={image} width="585" height="388" />
          </div>
        )}
      </div>
    </section>
  );
};
