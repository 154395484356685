import { IconProps } from '../types';

import classes from '../index.module.scss';
import { clsx } from 'clsx';

export const StarIconHalf = (props: IconProps) => {
  const { color, size, className, bold } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={clsx(
        className ?? '',
        classes.icon,
        color && classes[color],
        size && classes[size],
        bold && classes.bold
      )}
    >
      <path
        d="M13.9998 22.0008L8.81282 24.7261C8.50464 24.8877 8.15744 24.9598 7.81042 24.9345C7.4634 24.9092 7.13037 24.7874 6.8489 24.5828C6.56743 24.3783 6.34873 24.0991 6.21749 23.7769C6.08625 23.4546 6.04768 23.1021 6.10615 22.7591L7.09548 16.9818L2.89782 12.8891C2.64875 12.6459 2.47268 12.3378 2.3895 11.9997C2.30633 11.6616 2.31936 11.307 2.42712 10.976C2.53489 10.6449 2.73309 10.3506 2.99934 10.1263C3.26559 9.90192 3.58927 9.75652 3.93382 9.70647L9.73448 8.86414L12.3268 3.60714C12.4787 3.29318 12.7165 3.02874 13.0126 2.84453C13.3088 2.66032 13.6511 2.56391 13.9998 2.56647C14.349 2.56346 14.6918 2.65967 14.9884 2.84391C15.285 3.02814 15.5231 3.29282 15.6751 3.60714L18.2698 8.86647L24.0705 9.70647C24.4153 9.75655 24.7392 9.90214 25.0056 10.1268C25.272 10.3514 25.4702 10.6461 25.5777 10.9775C25.6853 11.309 25.6979 11.6639 25.6143 12.0021C25.5306 12.3404 25.3539 12.6484 25.1041 12.8915L20.9065 16.9818L21.8981 22.7591C21.9566 23.1021 21.9181 23.4546 21.7868 23.7769C21.6556 24.0991 21.4369 24.3783 21.1554 24.5828C20.8739 24.7874 20.5409 24.9092 20.1939 24.9345C19.8469 24.9598 19.4997 24.8877 19.1915 24.7261L13.9998 22.0008ZM13.9998 5.49248V19.6045C14.3031 19.6043 14.6018 19.678 14.8702 19.8191L19.4388 22.2225L18.5662 17.1358C18.5146 16.8372 18.5365 16.5305 18.63 16.2423C18.7235 15.9541 18.8858 15.693 19.1028 15.4815L22.7988 11.8788L17.6911 11.1368C17.3919 11.0933 17.1078 10.9778 16.8631 10.8002C16.6184 10.6226 16.4205 10.3882 16.2865 10.1171L13.9998 5.48781V5.49248Z"
        fill="currentColor"
      />
    </svg>
  );
};
