import { RefObject } from 'react';
import classes from './index.module.scss';
import { CMSLink, CMSLinkType } from '@/components/CMSLink';
import { clsx } from 'clsx';

export interface ButtonProps {
  link?: CMSLinkType;
  additionalClasses?: string;
  size?: 'big' | 'medium' | 'small' | 'extra-large' | 'extra-small';
  color?: 'yellow';
  buttonRef?: RefObject<HTMLAnchorElement>;
}

export const Button = (props: ButtonProps) => {
  const { link, additionalClasses = '', size = 'big', color, buttonRef } = props;

  if (!link) {
    return null;
  }

  return (
    <CMSLink
      className={clsx(classes.link, additionalClasses, { [classes[size]]: size, [classes[color ?? '']]: color })}
      buttonRef={buttonRef}
      {...link}
    />
  );
};
