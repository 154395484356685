import { useEffect, useState, useRef, RefObject } from 'react';

export default function useOnScreen<T extends HTMLElement>(ref: RefObject<T>): boolean {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting), {
      threshold: 0.1 // Optional: Add configuration to observer (like threshold)
    });
    observerRef.current = observer;

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const currentElement = ref.current;

    if (currentElement) {
      observerRef.current?.observe(currentElement);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}
