import { Fragment } from 'react';
import classes from './index.module.scss';
import { RichText } from '@/components/RichText';
import { Table } from '@/components/Table';
import { Video } from '@/components/Video';
import { ExtractBlockField } from '@/utils/types';
import { TableOfContents } from '@/components/TableOfContents';

type RichTextEditorProps = {
  richTextEditor: ExtractBlockField<'RichTextEditor', 'richTextEditor'>;
  title?: string;
  useContentOnly?: boolean;
};

const headingTagsRegExp = /h\d/;

type Text = {
  type?: string;
  children?: Text[];
  text?: string;
};

const getHeadings = (texts: Text[]): string[] => {
  const headingsFinder = (text: Text): string | string[] => {
    if (text.type && text.type.match(headingTagsRegExp) && Array.isArray(text.children)) {
      return text.children.map((child: Text) => child.text).join('');
    }

    if (Array.isArray(text.children)) {
      return text.children.map(headingsFinder).flat();
    }

    return '';
  };

  return texts.map(headingsFinder).flat().filter(Boolean);
};

export const RichTextEditor = (props: RichTextEditorProps) => {
  const content = props.richTextEditor?.content;
  const includeTableOfContents = props.richTextEditor?.includeTableOfContents;
  const title = props.title;
  const useContentOnly = props.useContentOnly;

  if (!content?.length) {
    return;
  }

  const texts = content
    .map(part => ('text' in part ? part.text : null))
    .filter(Boolean)
    .flat() as Text[];

  const headings = getHeadings(texts);

  const Content = () => (
    <>
      {title && <h1 className={title}>{title}</h1>}
      {headings.length && includeTableOfContents ? <TableOfContents contents={headings} /> : null}
      {content.map((part, index) => (
        <Fragment key={index}>
          {'text' in part && part.text && <RichText className={classes.rte} content={part.text} />}
          {'row' in part && <Table table={part} />}
          {'video' in part && (
            <div className={classes.videoWrapper}>
              <Video {...part.video} />
            </div>
          )}
        </Fragment>
      ))}
    </>
  );

  if (useContentOnly) {
    return <Content />;
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <Content />
      </div>
    </section>
  );
};
