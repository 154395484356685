'use client';

import { createContext, useContext, useState } from 'react';
import type { Dispatch, SetStateAction, ReactNode } from 'react';

export type StickyButton = {
  showStickyButton: boolean;
  setShowStickyButton: Dispatch<SetStateAction<boolean>>;
};

export const StickyButtonContext = createContext<StickyButton | undefined>(undefined);

export const StickyButtonProvider = ({ children }: { children: ReactNode }) => {
  const [showStickyButton, setShowStickyButton] = useState(false);

  return (
    <StickyButtonContext.Provider value={{ showStickyButton, setShowStickyButton }}>
      {children}
    </StickyButtonContext.Provider>
  );
};

export const useStickyButtonContext = (): StickyButton => {
  const context = useContext(StickyButtonContext);

  if (context === undefined) {
    throw new Error('useStickyButton must be used within a StickyButtonProvider');
  }

  return context;
};
