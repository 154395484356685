import type { ComponentProps } from 'react';

export const CrossIcon = (props: ComponentProps<'svg'>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM14.0306 12.9694C14.1003 13.0391 14.1556 13.1218 14.1933 13.2128C14.231 13.3039 14.2504 13.4015 14.2504 13.5C14.2504 13.5985 14.231 13.6961 14.1933 13.7872C14.1556 13.8782 14.1003 13.9609 14.0306 14.0306C13.9609 14.1003 13.8782 14.1556 13.7872 14.1933C13.6961 14.231 13.5985 14.2504 13.5 14.2504C13.4015 14.2504 13.3039 14.231 13.2128 14.1933C13.1218 14.1556 13.0391 14.1003 12.9694 14.0306L9 10.0603L5.03063 14.0306C4.88989 14.1714 4.69902 14.2504 4.5 14.2504C4.30098 14.2504 4.11011 14.1714 3.96937 14.0306C3.82864 13.8899 3.74958 13.699 3.74958 13.5C3.74958 13.301 3.82864 13.1101 3.96937 12.9694L7.93969 9L3.96937 5.03063C3.82864 4.88989 3.74958 4.69902 3.74958 4.5C3.74958 4.30098 3.82864 4.11011 3.96938 3.96938C4.11011 3.82864 4.30098 3.74958 4.5 3.74958C4.69902 3.74958 4.88989 3.82864 5.03063 3.96937L9 7.93969L12.9694 3.96937C13.1101 3.82864 13.301 3.74958 13.5 3.74958C13.699 3.74958 13.8899 3.82864 14.0306 3.96937C14.1714 4.11011 14.2504 4.30098 14.2504 4.5C14.2504 4.69902 14.1714 4.88989 14.0306 5.03063L10.0603 9L14.0306 12.9694Z"
      fill="currentColor"
    ></path>
  </svg>
);
