'use client';

import React from 'react';
import type { ExtractBlockField } from '@/utils/types';
import classes from './index.module.scss';
import { Media } from '@/components/Media';
import { Button } from '@/components/Button';
import { clsx } from 'clsx';

type CtaBlock = {
  ctaFields: ExtractBlockField<'Cta', 'ctaFields'>;
};

export const Cta = ({ ctaFields: { image, heading, description, link } }: CtaBlock) => {
  if (!image) {
    return null;
  }

  return (
    <section className={classes.section}>
      <div className={clsx('container', classes.container)}>
        <div className={classes.row}>
          <div className={clsx(classes.col, classes.small)}>
            <Media source={image} width={542} height={389} className={classes.image} />
          </div>

          <div className={clsx(classes.col, classes.big)}>
            <div className={classes.content}>
              {heading && <h2 className={classes.heading}>{heading}</h2>}

              {description && <p className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />}

              <div className={classes.btnWrapper}>{link && <Button link={link} additionalClasses={classes.btn} />}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
