'use client';
import '@atomicleads/ext-satellite-form/index.css';
import classes from './index.module.scss';
import { ContactForm, Login, ForgotPassword, Registration } from '@atomicleads/ext-satellite-form';
import { usePostHog } from 'posthog-js/react';
import { ExtractBlockField } from '@/utils/types';

type CommonComponentProps = {
  mediaBlockFields: ExtractBlockField<'commonComponent', 'mediaBlockFields'>;
};

export const CommonComponent = (props: CommonComponentProps) => {
  const posthog = usePostHog();

  const component = props?.mediaBlockFields?.component;
  let Component = <></>;

  switch (component) {
    case 'login':
      Component = <Login productFocus={'extension'} posthog={posthog} />;
      break;
    case 'registration':
      Component = <Registration productFocus={'extension'} disablePasswordConfirm posthog={posthog} />;
      break;
    case 'contactUs':
      Component = <ContactForm color={'#1D77B8'} />;
      break;
    case 'passwordRecovery':
      Component = <ForgotPassword />;
      break;
    default:
      return null;
  }

  return <section className={classes.section}>{Component}</section>;
};
