import classes from './index.module.scss';

type Table = {
  row?:
    | {
        column?:
          | {
              text?: string | null;
              id?: string | null;
            }[]
          | null;
        id?: string | null;
      }[]
    | null;
  id?: string | null;
  blockName?: string | null;
  blockType: 'RichTextContentTable';
} | null;

export const Table = ({ table }: { table?: Table }) => {
  if (!table || !table.row || !table.row[0]) {
    return null;
  }

  return (
    <table className={classes.table}>
      <thead>
        <tr>{table.row[0]?.column?.map((column, index) => <th key={column.id || index}>{column.text}</th>)}</tr>
      </thead>

      <tbody>
        {table.row.map((row, index) =>
          index ? (
            <tr key={row.id || index}>
              {row.column?.map((column, index) => <td key={column.id || index}>{column.text}</td>)}
            </tr>
          ) : null
        )}
      </tbody>
    </table>
  );
};
