import { IconProps } from '../types';

import classes from '../index.module.scss';
import { clsx } from 'clsx';

export const File = (props: IconProps) => {
  const { color, size, className, bold } = props;

  return (
    <svg
      className={clsx(
        className ?? '',
        classes.icon,
        color && classes[color],
        size && classes[size],
        bold && classes.bold
      )}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 22.5H17.5M8.75 17.5H10M8.75 12.5H12.5M8.75 2.5H20.625L26.25 8.125V23.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 25.625V8.125C3.75 7.62772 3.94754 7.15081 4.29917 6.79917C4.65081 6.44754 5.12772 6.25 5.625 6.25H17.815C18.0138 6.25018 18.2045 6.32931 18.345 6.47L22.28 10.405C22.35 10.4749 22.4054 10.5579 22.4432 10.6493C22.4809 10.7407 22.5002 10.8386 22.5 10.9375V25.625C22.5 26.1223 22.3025 26.5992 21.9508 26.9508C21.5992 27.3025 21.1223 27.5 20.625 27.5H5.625C5.12772 27.5 4.65081 27.3025 4.29917 26.9508C3.94754 26.5992 3.75 26.1223 3.75 25.625Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 6.25V10.5C17.5 10.6989 17.579 10.8897 17.7197 11.0303C17.8603 11.171 18.0511 11.25 18.25 11.25H22.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
