import classes from './styles.module.scss';
import { ExtractBlockField } from '@/utils/types';
import { Media } from '@/components/Media';
import { clsx } from 'clsx';
import { CSSProperties } from 'react';

type IconsProps = ExtractBlockField<'iconsWithText', 'mediaBlockFields'> & {
  sectionStyles?: CSSProperties;
  containerStyles?: CSSProperties;
};

export const Icons = ({ icons, title, sectionStyles = {}, containerStyles = {} }: IconsProps) => {
  if (!icons?.length) {
    return null;
  }

  return (
    <section className={classes.section} style={sectionStyles}>
      <div className={clsx(classes.container, 'container')} style={containerStyles}>
        {title && <h2 className={classes.title}>{title}</h2>}

        <div className={clsx(classes.row, { [classes.spaceBetween]: icons.length === 4 })}>
          {icons.map(({ icon }, index) => (
            <div key={index} className={classes.col}>
              {icon && <Media source={icon} width={167} height={167} />}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
