import type { ComponentProps } from 'react';

export const Chevron = (props: ComponentProps<'svg'>) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.5 8.75L12 16.25L19.5 8.75"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
