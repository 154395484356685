'use client';

import React from 'react';
import type { ExtractBlockField } from '@/utils/types';
import classes from './index.module.scss';
import { Media } from '@/components/Media';
import { RichText } from '@/components/RichText';
import { clsx } from 'clsx';

type BenefitsBlock = {
  benefitsBlockFields: ExtractBlockField<'benefitsBlock', 'benefitsBlockFields'>;
};

export const BenefitsBlock = ({
  benefitsBlockFields: { benefits, heading, description, disclaimer }
}: BenefitsBlock) => {
  if (!benefits) {
    return null;
  }

  return (
    <section className={classes.section}>
      <div className={clsx('container', classes.container)}>
        {benefits.length && (
          <div>
            <div className={classes.benefitsRow}>
              {benefits.map(({ image, heading, description }, index) => (
                <div className={classes.benefit} key={index}>
                  {image && <Media source={image} height={104} className={classes.benefitImage} />}

                  {heading && <h3 className={classes.benefitHeading}>{heading}</h3>}

                  {description && <p className={classes.benefitDescription}>{description}</p>}
                </div>
              ))}
            </div>

            <div className={'border-sep full'} />
          </div>
        )}

        <div className={classes.content}>
          {heading && <h2 className={classes.heading}>{heading}</h2>}

          {description && <p className={classes.description}>{description}</p>}

          {disclaimer && <RichText content={disclaimer} className={classes.disclaimer} />}
        </div>
      </div>
    </section>
  );
};
