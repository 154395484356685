'use client';

import React from 'react';
import type { ExtractBlockField } from '@/utils/types';
import classes from './index.module.scss';
import { Media } from '@/components/Media';
import { RichText } from '@/components/RichText';
import { clsx } from 'clsx';

type FaqsProps = {
  faqsFields: ExtractBlockField<'Faqs', 'faqsFields'>;
};

export const Faqs = ({ faqsFields: { image, items } }: FaqsProps) => {
  if (!items?.length) {
    return null;
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <div className={classes.row}>
          <div className={clsx(classes.col, classes.big)}>
            <div className={classes.content}>
              {items.map(({ heading, description }, index) => (
                <div key={index}>
                  {heading && <h3 dangerouslySetInnerHTML={{ __html: heading }} className={classes.heading} />}

                  {description && <RichText content={description} className={classes.description} />}
                </div>
              ))}
            </div>
          </div>

          {image && (
            <div className={clsx(classes.col, classes.small)}>
              <Media source={image} width={359} height={359} className={classes.image} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
