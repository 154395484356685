import React, { CSSProperties } from 'react';
import classes from './styles.module.scss';
import { ExtractBlockField } from '@/utils/types';
import { Media } from '@/components/Media';

type IconsWithDescriptionsProps = ExtractBlockField<'iconsWithText', 'mediaBlockFields'> & {
  sectionStyles?: CSSProperties;
  containerStyles?: CSSProperties;
};
export function IconsWithDescriptions({
  title,
  icons,
  sectionStyles = {},
  containerStyles = {}
}: IconsWithDescriptionsProps) {
  if (!icons?.length) {
    return null;
  }

  return (
    <>
      <div className={classes.section} style={sectionStyles}>
        <div className="container" style={containerStyles}>
          {title && <h2 className={classes.title}>{title}</h2>}
          <div className={classes.row}>
            {icons.map(({ icon, title, description }, index) => {
              if (!icon) {
                return null;
              }

              return (
                <div className={classes.col} key={index}>
                  <div className={classes.card}>
                    <div className={classes.iconWrapper}>
                      <Media source={icon} width={155} height={99} />
                    </div>

                    {title && <h4 className={classes.title}>{title}</h4>}

                    {description && <p className={classes.description}>{description}</p>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
