import { IconProps } from '../types';

import classes from '../index.module.scss';
import { clsx } from 'clsx';

export const StarIcon = (props: IconProps) => {
  const { color, size, className, bold } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={clsx(
        className ?? '',
        classes.icon,
        color && classes[color],
        size && classes[size],
        bold && classes.bold
      )}
    >
      <path
        d="M12.701 3.90736C13.233 2.83036 14.77 2.83036 15.301 3.90736L17.993 9.36036L24.01 10.2344C25.2 10.4074 25.674 11.8694 24.814 12.7084L20.459 16.9524L21.487 22.9454C21.691 24.1294 20.447 25.0324 19.384 24.4734L14 21.6434L8.61904 24.4734C7.55504 25.0334 6.31204 24.1294 6.51504 22.9454L7.54304 16.9524L3.18804 12.7084C2.32804 11.8684 2.80304 10.4084 3.99204 10.2344L10.009 9.36036L12.7 3.90736H12.701Z"
        fill="currentColor"
      />
    </svg>
  );
};
