import { ExtractBlockField } from '@/utils/types';
import { Icons } from '@/components/Icons';
import { IconsWithTitles } from '@/components/IconsWIthTitles';
import { IconsWithDescriptions } from '@/components/IconsWIthDescriptions';
import { CSSProperties } from 'react';
import classes from './index.module.scss';

type IconsWithTextProps = {
  mediaBlockFields: ExtractBlockField<'iconsWithText', 'mediaBlockFields'>;
};

type IconsWithTextContentProps = Pick<IconsWithTextProps['mediaBlockFields'], 'title' | 'icons'> & {
  sectionStyles?: CSSProperties;
  containerStyles?: CSSProperties;
  hasDescription: boolean;
  hasTitle: boolean;
};

const IconWithTextContent = ({
  title,
  icons,
  hasDescription,
  hasTitle,
  sectionStyles = {},
  containerStyles = {}
}: IconsWithTextContentProps) => {
  if (hasDescription) {
    return (
      <IconsWithDescriptions
        title={title}
        icons={icons}
        sectionStyles={sectionStyles}
        containerStyles={containerStyles}
      />
    );
  }

  if (hasTitle) {
    return (
      <IconsWithTitles title={title} icons={icons} sectionStyles={sectionStyles} containerStyles={containerStyles} />
    );
  }

  return <Icons title={title} icons={icons} sectionStyles={sectionStyles} containerStyles={containerStyles} />;
};

export const IconsWithText = (props: IconsWithTextProps) => {
  const { backgroundColor, icons, title, background, roundedCorners } = props.mediaBlockFields;

  if (!icons || !icons.length) {
    return null;
  }

  const sectionStyles = backgroundColor && !roundedCorners ? { backgroundColor } : {};

  // if border radius is set, add background to container instead of section
  const containerStyles =
    backgroundColor && roundedCorners
      ? {
          backgroundColor,
          borderRadius: '16px'
        }
      : {};

  const hasDescription = icons.some(icon => icon.description);
  const hasTitle = icons.some(icon => icon.title);
  const backgroundImage =
    (background && typeof background === 'object' && `url('${process.env.NEXT_PUBLIC_CMS_URL}${background.url}')`) ||
    undefined;

  return (
    <div className={classes.sectionWrapper}>
      <IconWithTextContent
        icons={icons}
        title={title}
        hasTitle={hasTitle}
        hasDescription={hasDescription}
        sectionStyles={sectionStyles}
        containerStyles={containerStyles}
      />
      <div className={classes.backgroundImage} style={{ backgroundImage }}></div>
    </div>
  );
};
