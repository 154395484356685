import React, { CSSProperties } from 'react';
import classes from './styles.module.scss';
import { ExtractBlockField } from '@/utils/types';
import { Media } from '@/components/Media';

type IconsWithTitlesProps = ExtractBlockField<'iconsWithText', 'mediaBlockFields'> & {
  sectionStyles?: CSSProperties;
  containerStyles?: CSSProperties;
};
export const IconsWithTitles = ({ icons, title, sectionStyles = {}, containerStyles = {} }: IconsWithTitlesProps) => {
  if (!icons?.length) {
    return null;
  }
  return (
    <section>
      <div className={classes.container} style={sectionStyles}>
        {title && <h2 className={classes.title}>{title}</h2>}

        <div className={classes.iconsContainer} style={containerStyles}>
          {icons.map(({ icon, title }, index) => {
            if (!icon) {
              return null;
            }

            return (
              <div className={classes.iconBlock} key={index}>
                {<Media source={icon} width={170} height={170} />}

                {title && <p className={classes.iconTitle}>{title}</p>}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
