import { Media } from '@/components/Media';
import type { ExtractBlockField } from '@/utils/types';
import classes from './index.module.scss';
import { clsx } from 'clsx';

type FeaturesProps = {
  features: ExtractBlockField<'features', 'features'>;
};

export const Features = ({ features: { feature } }: FeaturesProps) => (
  <section className={classes.wrapper}>
    <div className={clsx(classes.container, 'container')}>
      {feature && !!feature.length && (
        <div className={classes.row}>
          {feature.map((featureItem, index) => (
            <div className={classes.col} key={featureItem.id || index}>
              <div className={classes.featureItem}>
                {featureItem.icon && (
                  <Media
                    source={featureItem.icon}
                    width={170}
                    height={170}
                    className={clsx(classes.featureItemIcon, classes.tablet)}
                  />
                )}

                {(featureItem.title || featureItem.description) && (
                  <div className={classes.featureItemContent}>
                    {featureItem.icon && (
                      <Media source={featureItem.icon} width={170} height={170} className={classes.featureItemIcon} />
                    )}

                    {featureItem.title && <p className={classes.featureItemTitle}>{featureItem.title}</p>}

                    {featureItem.description && (
                      <p className={classes.featureItemDescription}>{featureItem.description}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </section>
);
