import type { ComponentProps } from 'react';

export const CheckmarkIcon = (props: ComponentProps<'svg'>) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.6 13.2988L14.65 6.37808L13.25 5.00375L7.6 10.5502L4.75 7.75242L3.35 9.12675L7.6 13.2988ZM2 18.0108C1.45 18.0108 0.979002 17.8184 0.587002 17.4336C0.195002 17.0488 -0.000664969 16.5868 1.69779e-06 16.0475V2.30416C1.69779e-06 1.76424 0.196002 1.30187 0.588002 0.917061C0.980002 0.532247 1.45067 0.340168 2 0.340822H16C16.55 0.340822 17.021 0.533229 17.413 0.918043C17.805 1.30286 18.0007 1.76489 18 2.30416V16.0475C18 16.5874 17.804 17.0498 17.412 17.4346C17.02 17.8194 16.5493 18.0115 16 18.0108H2Z"
      fill="currentColor"
    ></path>
  </svg>
);
