import type { Media } from '@/payload-types';
import type { Video as VideoType } from '@/utils/types';
import { VideoPlayer } from '../VideoPlayer';

export type VideoProps = VideoType & {
  posterClassName?: string;
};

const isVideoYoutube = (url: string): boolean => {
  try {
    const videoUrl = new URL(url);
    return videoUrl.hostname === 'www.youtube.com' || videoUrl.hostname === 'youtube.com';
  } catch (e) {
    return false;
  }
};

const getYoutubeVideoId = (url: string): string | null => {
  const videoUrl = new URL(url);
  if (videoUrl.hostname === 'www.youtube.com' || videoUrl.hostname === 'youtube.com') {
    if (videoUrl.searchParams.has('v')) {
      return videoUrl.searchParams.get('v');
    } else {
      return videoUrl.pathname.split('/')?.at(-1) || null;
    }
  } else if (videoUrl.hostname === 'youtu.be') {
    return videoUrl.pathname.substr(1);
  }
  return null;
};

const getYouTubeThumbnailUrl = (videoId: string) => `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

const getPoster = (videoUrl: string, poster: Media | string | null | undefined): string | Media => {
  if (typeof poster === 'string') return poster;

  if (poster && poster.url) return poster;

  if (isVideoYoutube(videoUrl)) {
    const id = getYoutubeVideoId(videoUrl);

    if (id) return getYouTubeThumbnailUrl(id);
  }

  return '';
};

export const Video = (props: VideoProps) => {
  if (!props.url) {
    return null;
  }

  const posterUrl = getPoster(props.url, props.poster);

  return <VideoPlayer {...props} poster={posterUrl} />;
};
