import classes from './index.module.scss';
import { File } from '@/icons/File';
import Link from 'next/link';
import { toKebabCase } from '@/utils/to-kebab-case';

export const TableOfContents = ({ contents }: { contents: string[] }) => {
  if (!contents.length) {
    return null;
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.header}>
          <File />

          <h2 className={classes.heading}>Table of Contents</h2>
        </div>

        <div className={classes.content}>
          <ul className={classes.list}>
            {contents.map((item, index) => (
              <li key={index} className={classes.item}>
                <Link href={`#${toKebabCase(item)}`}>{item}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
