'use client';

import classes from './index.module.scss';
import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { RichText } from '@/components/RichText';
import { ExtractBlockField } from '@/utils/types';
import { Chevron } from '@/icons/Chevron';
import { clsx } from 'clsx';
import { CSSProperties } from 'react';

type AccordionsProps = {
  accordions: ExtractBlockField<'accordions', 'accordions'>;
  title?: string;
};

export const Accordions = (props: AccordionsProps) => {
  const {
    title,
    accordions: { accordions, showTitle, appearance, descriptionColor }
  } = props || {};

  if (!accordions?.length) {
    return;
  }

  const showAdditionalText = appearance === 'withAdditionalText';

  return (
    <section className={classes.section} style={{ '--description-bg': descriptionColor } as CSSProperties}>
      <div className={clsx('container', classes.container)}>
        {title && showTitle ? <h1>{title}</h1> : null}

        {accordions.map((accordion, index) => {
          if (!accordion.heading || !accordion.description) {
            return null;
          }

          return (
            <Collapsible key={index} openOnInit={!index} transTime={250} classPrefix={classes.accordion}>
              <CollapsibleToggler className={classes.accordionHeading}>
                <span className={classes.accordionHeadingText}>{accordion.heading}</span>

                <span className={classes.desktop}>
                  {showAdditionalText && (
                    <>
                      <span className={classes.closed}>Show</span>

                      <span className={classes.opened}>Hide</span>
                    </>
                  )}

                  <Chevron />
                </span>
              </CollapsibleToggler>

              <CollapsibleContent>
                <div className={classes.accordionDescription}>
                  <RichText content={accordion.description} />
                </div>
              </CollapsibleContent>
            </Collapsible>
          );
        })}
      </div>
    </section>
  );
};
