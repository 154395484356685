import React, { ComponentProps } from 'react';

export const PlayIcon = (props: ComponentProps<'svg'>) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="39.6748" cy="39.6748" r="28.6179" fill="white" />
    <path
      d="M40 1.25C18.5938 1.25 1.25 18.5938 1.25 40C1.25 61.4062 18.5938 78.75 40 78.75C61.4062 78.75 78.75 61.4062 78.75 40C78.75 18.5938 61.4062 1.25 40 1.25ZM58.0781 43.75L30.5781 59.5312C28.1094 60.9062 25 59.1406 25 56.25V23.75C25 20.875 28.0938 19.0938 30.5781 20.4688L58.0781 37.1875C60.6406 38.625 60.6406 42.3281 58.0781 43.75Z"
      fill="black"
    />
  </svg>
);
