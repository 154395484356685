import { ExtractBlockField } from '@/utils/types';
import classes from './index.module.scss';
import { Media } from '@/components/Media';
import { StarIcon } from '@/icons/StarIcon';
import { StarIconHalf } from '@/icons/StarIconHalf';
import { Button } from '@/components/Button';

type ReviewsProps = {
  extendedReviews: ExtractBlockField<'extended-reviews', 'extendedReviews'>;
};

type Reviews = NonNullable<ReviewsProps['extendedReviews']>;

type ReviewProps = Pick<Reviews, 'starsPosition'> & NonNullable<Reviews['reviews']>[number];

const Review = ({ authorImage, author, heading, quote, score, starsPosition }: ReviewProps) => {
  const starsPositionTop = starsPosition === 'top';

  return (
    <div className={classes.review}>
      <div className={classes.reviewHeader}>
        {authorImage && <Media className={classes.authorImage} source={authorImage} width={80} height={80} />}

        {author && <div className={classes.reviewAuthor}>{author}</div>}
      </div>

      {starsPositionTop && <Stars score={Number(score)} />}

      {heading && <h3 className={classes.reviewTitle}>{heading}</h3>}

      <p className={classes.quote}>{quote}</p>

      {!starsPositionTop && <Stars score={Number(score)} />}
    </div>
  );
};

const Stars = ({ score }: { score: number }) => {
  if (!score) {
    return null;
  }

  return (
    <div className={classes.stars}>
      {[...Array(Math.floor(score))].map((e, i) => (
        <StarIcon key={i} />
      ))}

      {!Number.isInteger(score) ? <StarIconHalf /> : ''}
    </div>
  );
};

export const ExtendedReviews = (props: ReviewsProps) => {
  const heading = props.extendedReviews?.heading;
  const reviews = props.extendedReviews?.reviews;
  const link = props.extendedReviews?.link;
  const starsPosition = props.extendedReviews?.starsPosition;

  if (!reviews?.length) {
    return null;
  }

  return (
    <section className={classes.section}>
      <div className="container">
        {heading && <h2 className={classes.title}>{heading}</h2>}

        <div className={classes.row}>
          {reviews.map((review, index) => (
            <div className={classes.col} key={index}>
              <Review {...review} starsPosition={starsPosition} />
            </div>
          ))}
        </div>

        {link && (
          <div className={classes.buttonWrapper}>
            <Button link={link} additionalClasses={classes.button} />
          </div>
        )}
      </div>
    </section>
  );
};
